import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-md-0 pl-3" }
const _hoisted_2 = { class: "pl-md-0 pl-3" }
const _hoisted_3 = { class: "pl-md-0 pl-3" }
const _hoisted_4 = { class: "pl-md-0 pl-3" }
const _hoisted_5 = { class: "pl-md-0 pl-3" }
const _hoisted_6 = { class: "pl-md-0 pl-3" }
const _hoisted_7 = { class: "pl-md-0 pl-3" }
const _hoisted_8 = { class: "hidden-from-screen" }
const _hoisted_9 = { class: "pl-md-0 pl-3" }
const _hoisted_10 = { class: "pl-md-0 pl-3" }
const _hoisted_11 = { class: "pl-md-0 pl-3" }
const _hoisted_12 = { class: "pl-md-0 pl-3" }
const _hoisted_13 = { class: "pl-md-0 pl-3" }
const _hoisted_14 = { class: "pl-md-0 pl-3" }
const _hoisted_15 = { class: "pl-md-0 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListBlockView = _resolveComponent("ListBlockView")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_GeneralTableView = _resolveComponent("GeneralTableView")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTableView = _resolveComponent("CustomTableView")!
  const _component_BreedingGeneralTable = _resolveComponent("BreedingGeneralTable")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_crvCooperationLogo = _resolveComponent("crvCooperationLogo")!
  const _component_crv_pdf_page = _resolveComponent("crv-pdf-page")!
  const _component_crv_html_to_pdf = _resolveComponent("crv-html-to-pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, {
      fluid: "",
      class: "pl-0 pr-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "pr-md-6 pr-sm-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ListBlockView, {
                  base: _ctx.base,
                  class: "mb-6"
                }, null, 8, ["base"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "pl-md-6 pl-sm-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_GeneralTableView, {
                  class: "mb-6",
                  "body-rows": _ctx.mapCustomBreedingValues(
            _ctx.generalBreedingValueGroupList[0].breedingValueList,
            _ctx.generalBreedingValueGroupList[0].tagName
          )
          ,
                  "header-rows": _ctx.generalProdColumns
                }, null, 8, ["body-rows", "header-rows"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "pr-md-6 pr-sm-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("details.production.header")), 1),
                (_ctx.productionBreedingValueGroupList && _ctx.productionBreedingValueGroupList[0] && _ctx.productionBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_CustomTableView, {
                      key: 0,
                      class: "mb-6",
                      "body-rows": _ctx.mapCustomProductionBreedingValues(
            _ctx.productionBreedingValueGroupList[0].breedingValueList,
            _ctx.productionBreedingValueGroupList[0].tagName
          ),
                      "header-rows": _ctx.customColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("details.efficiency.header")), 1),
                (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                      key: 1,
                      hideHeader: "",
                      class: "mb-6",
                      "body-rows": _ctx.mapGeneralBreedingValues(_ctx.efficiencyBreedingValueGroupList[0].breedingValueList, _ctx.efficiencyBreedingValueGroupList[0].tagName),
                      "header-rows": _ctx.generalColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("details.health.header")), 1),
                (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                      key: 2,
                      class: "mb-6",
                      "body-rows": _ctx.mapGeneralBreedingValues(_ctx.healthBreedingValueGroupList[0].breedingValueList, _ctx.healthBreedingValueGroupList[0].tagName),
                      "header-rows": _ctx.generalColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("details.functional.header")), 1),
                (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                      key: 3,
                      class: "mb-6",
                      "body-rows": _ctx.mapGeneralBreedingValues(_ctx.functionalBreedingValueGroupList[0].breedingValueList, _ctx.functionalBreedingValueGroupList[0].tagName),
                      "header-rows": _ctx.generalColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "6",
              class: "pl-md-6 pl-sm-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("details.birth.header")), 1),
                (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                      key: 0,
                      class: "mb-6",
                      "body-rows": _ctx.mapGeneralBreedingValues(_ctx.birthBreedingValueGroupList[0].breedingValueList, _ctx.birthBreedingValueGroupList[0].tagName),
                      "header-rows": _ctx.generalColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("details.exteriorabove.header")), 1),
                (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                      key: 1,
                      class: "mb-6",
                      "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList,
            _ctx.exteriorAboveBreedingValueGroupList[0].tagName
          )
          ,
                      "header-rows": _ctx.generalColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1),
                (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
                  ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                      key: 2,
                      withSubText: "",
                      class: "mb-6",
                      "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList,
            _ctx.exteriorBelowBreedingValueGroupList[0].tagName
          )
          ,
                      "header-rows": _ctx.generalColumns
                    }, null, 8, ["body-rows", "header-rows"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_crv_html_to_pdf, {
        ref: "pdf",
        orientation: "portrait",
        name: _ctx.generateFileName(),
        pageNumberLabel: _ctx.getPageName()
      }, {
        default: _withCtx(() => [
          _createVNode(_component_crv_pdf_page, {
            "is-first-page": "",
            title: _ctx.bullDetail.shortName ? _ctx.bullDetail.shortName : '-',
            subtitle: _ctx.bullDetail.fullName,
            identifier: _ctx.bullDetail.artificialInseminationNumber + ' | ' + _ctx.bullDetail.lifeNumber,
            footerText: "",
            phoneNumber: "",
            "date-label": _ctx.getDate()
          }, {
            logo: _withCtx(() => [
              _createVNode(_component_crvCooperationLogo, { class: "custom-logo" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_container, {
                fluid: "",
                class: "pl-0 pr-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "7",
                        class: "pr-md-6 pr-sm-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ListBlockView, {
                            base: _ctx.base,
                            class: "summary-print"
                          }, null, 8, ["base"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "5" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_GeneralTableView, {
                            "body-rows": _ctx.mapCustomBreedingValues(
                  _ctx.generalBreedingValueGroupList[0].breedingValueList,
                  _ctx.generalBreedingValueGroupList[0].tagName
                )
                ,
                            "header-rows": _ctx.generalProdColumns
                          }, null, 8, ["body-rows", "header-rows"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "6",
                        class: "pr-md-6 pr-sm-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t("details.production.header")), 1),
                          (_ctx.productionBreedingValueGroupList && _ctx.productionBreedingValueGroupList[0] && _ctx.productionBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_CustomTableView, {
                                key: 0,
                                class: "mb-2",
                                "body-rows": _ctx.mapCustomProductionBreedingValues(
                  _ctx.productionBreedingValueGroupList[0].breedingValueList,
                  _ctx.productionBreedingValueGroupList[0].tagName
                ),
                                "header-rows": _ctx.customColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t("details.efficiency.header")), 1),
                          (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                                key: 1,
                                hideHeader: "",
                                class: "mb-2",
                                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.efficiencyBreedingValueGroupList[0].breedingValueList, _ctx.efficiencyBreedingValueGroupList[0].tagName),
                                "header-rows": _ctx.generalColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$t("details.health.header")), 1),
                          (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                                key: 2,
                                class: "mb-6",
                                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.healthBreedingValueGroupList[0].breedingValueList, _ctx.healthBreedingValueGroupList[0].tagName),
                                "header-rows": _ctx.generalColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "6",
                        class: "pr-md-6 pr-sm-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t("details.functional.header")), 1),
                          (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                                key: 0,
                                class: "mb-2",
                                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.functionalBreedingValueGroupList[0].breedingValueList, _ctx.functionalBreedingValueGroupList[0].tagName),
                                "header-rows": _ctx.generalColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t("details.birth.header")), 1),
                          (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                                key: 1,
                                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.birthBreedingValueGroupList[0].breedingValueList, _ctx.birthBreedingValueGroupList[0].tagName),
                                "header-rows": _ctx.generalColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t("details.exteriorabove.header")), 1),
                          (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                                key: 2,
                                "body-rows": _ctx.mapGeneralBreedingValues(
                    _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList,
                    _ctx.exteriorAboveBreedingValueGroupList[0].tagName
                  )
                  ,
                                "header-rows": _ctx.generalColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["title", "subtitle", "identifier", "date-label"]),
          _createVNode(_component_crv_pdf_page, {
            title: _ctx.bullDetail.shortName ? _ctx.bullDetail.shortName : '-',
            subtitle: _ctx.bullDetail.fullName,
            identifier: `${_ctx.bullDetail.artificialInseminationNumber} | ${_ctx.bullDetail.lifeNumber}`,
            footerText: "",
            phoneNumber: "",
            "date-label": _ctx.getDate()
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, {
                fluid: "",
                class: "pl-0 pr-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "6",
                        class: "pr-md-6 pr-sm-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1),
                          (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
                            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                                key: 0,
                                withSubText: "",
                                "body-rows": _ctx.mapGeneralBreedingValues(
                    _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList,
                    _ctx.exteriorBelowBreedingValueGroupList[0].tagName
                  )
                  ,
                                "header-rows": _ctx.generalColumns
                              }, null, 8, ["body-rows", "header-rows"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["title", "subtitle", "identifier", "date-label"])
        ]),
        _: 1
      }, 8, ["name", "pageNumberLabel"])
    ])
  ], 64))
}