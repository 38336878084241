import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crv_search_input = _resolveComponent("crv-search-input")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_crv_fallback_content = _resolveComponent("crv-fallback-content")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_SireFooter = _resolveComponent("SireFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("home.header")), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_crv_search_input, {
          class: "mb-4 w-25 min-width",
          modelValue: _ctx.searchValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
          placeholder: (_ctx.windowWidth > _ctx.Device.MOBILEMAX) ? _ctx.$t('home.placeholder-text') : _ctx.$t('home.placeholder-text-mobile'),
          hint: _ctx.showHelperTextOrResultCount,
          "persistent-hint": true,
          "hide-details": false,
          error: _ctx.error,
          "error-messages": _ctx.error ? [_ctx.$t('home.refineTheSearch')] : [],
          onCleared: _ctx.resetSorting
        }, null, 8, ["modelValue", "placeholder", "hint", "error", "error-messages", "onCleared"]),
        (_ctx.showTable)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 0,
              headers: _ctx.headerRows,
              items: _ctx.bullJson,
              loading: _ctx.isLoading,
              "items-per-page": _ctx.itemsPerPage,
              "items-per-page-options": _ctx.itemsPerPageOptions,
              "item-key": "name",
              "sort-by": _ctx.sortBy,
              "onUpdate:sortBy": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortBy) = $event)),
              "fixed-header": "",
              hover: "",
              density: "compact",
              "onClick:row": _ctx.handleClickBodyRowNew
            }, {
              [`item.artificialInseminationNumber`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  "search-words": [_ctx.sanitizedSearchValue],
                  "text-to-highlight": item.artificialInseminationNumber || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.fullName`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  class: "text-nowrap",
                  "search-words": [_ctx.sanitizedSearchValue],
                  "text-to-highlight": item.fullName || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.shortName`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  class: "text-nowrap",
                  "search-words": [_ctx.sanitizedSearchValue],
                  "text-to-highlight": item.shortName || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.interbullNumber`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  "search-words": [_ctx.sanitizedSearchValue],
                  "text-to-highlight": item.interbullNumber || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.dateOfBirth`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(item.dateOfBirth)), 1)
              ]),
              [`item.percentageFat`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(typeof item.percentageFat === 'number' ? item.percentageFat.toFixed(2) : '-'), 1)
              ]),
              [`item.percentageProtein`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(typeof item.percentageProtein === 'number' ? item.percentageProtein.toFixed(2) : '-'), 1)
              ]),
              [`item.lifeNumber`]: _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(item.lifeNumber), 1)
              ]),
              "no-data": _withCtx(() => [
                _createVNode(_component_crv_fallback_content, {
                  class: "overflow-hidden",
                  "image-name": "noSearchResults",
                  text: _ctx.$t('home.foundNoneText')
                }, null, 8, ["text"])
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page", "items-per-page-options", "sort-by", "onClick:row"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_SireFooter, { showDisclaimer: "" })
  ], 64))
}